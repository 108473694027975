import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from './include/Navbar';
import Footer from './include/Footer';
import Contact from './include/Contact';

import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
// Images
import stairs from '../assets/images/stairs.png'
import calendar from '../assets/images/calendar.png'
import phone from '../assets/images/phone-call.png'

import { API } from '../constant';


const Clothes = () => {
    const { slug } = useParams();
    const [error, setError] = useState(null);
    const [storeOffices, setStoreOffices] = useState([]);

    const server = API;

    const { i18n } = useTranslation();

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios.get(`${server}/api/subcaregories?populate[store_offices][populate]=*&filters[Slug]=${slug}&locale=${locale}`)
            .then((response) => {
                if (response.data && response.data.data) {

                    setStoreOffices(response.data.data[0].attributes.store_offices.data)

                } else {
                    setError("Data structure from API is not as expected.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));


    }, [slug, error, server, i18n.language]);


    return (
        <div>
            <Navbar />
            <section>
                <div className="advertisingClothes">
                    <div className="advertisingClothes-content">

                        <div className="advertisingClothes-box-group">
                            {storeOffices && storeOffices.length > 0 ? (
                                storeOffices.map(({ id, attributes }, index) => {
                                    const imageFirst = attributes.Image1 && attributes.Image1.data ? attributes.Image1.data.attributes.url : '';
                          			const companyName = attributes.center && attributes.center.data ? attributes.center.data.attributes.Slug : '';
                                    const subcaregory = attributes.subcaregory && attributes.subcaregory.data ? attributes.subcaregory.data.attributes.Name : '';
                          
                                    return (
                                        <div className="advertisingClothes-box" key={id}>
                                            <Link to={`/${companyName}/${attributes.Slug}`}>
                                                <div className="advertisingClothes-box-img"><img src={server + imageFirst} alt='store' /></div>
                                                <div>
                                                    <h2>{attributes.Name}</h2>
                                                    <p>{subcaregory}</p>
                                                </div>
                                                <div className="advertisingClothes-inline-data"><img src={calendar} alt="calendar" /><p>{attributes.MondayFriday}</p></div>
                                                <div className="advertisingClothes-inline-data"><img src={phone} alt="phone" /><p>{attributes.WhatsAppNumber}</p></div>
                                                <div className="advertisingClothes-inline-data"><img src={stairs} alt="floor" /><p>{attributes.Floor}</p></div>
                                            </Link>
                                        </div>
                                    )
                                })
                            ) : (
                                <p>No data available.</p>
                            )}
                        </div>


                    </div>
                </div>
            </section>
            <Contact />
            <Footer />
        </div>
    );
};

export default Clothes;
