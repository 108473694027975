import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";

import axios from "axios";
import { Link } from "react-router-dom"

import Navbar from './include/Navbar';

import { useTranslation } from 'react-i18next';

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSquareXmark, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Footer from './include/Footer';

import { API } from '../constant';

const Center = () => {
    const { slug } = useParams();
    const location = useLocation();


    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [error, setError] = useState(null);
    const [cities, setCities] = useState([]);
    const [centers, setCenters] = useState([]);
    const [centers1, setCenters1] = useState([]);
    const [centerImgs, setCenterImgs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [countryImg, setCountryImg] = useState('');

    const { t, i18n } = useTranslation();

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    const server = API

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios
            .get(`${server}/api/cities?populate=*&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {
                    setCities(data.data);
                } else {
                    console.log(error)
                    setError("No data found for this slug.");
                }
            })
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/countries?populate=*&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {

                    const country_img = data.data[0].attributes.Flag.data.attributes.url || ''
                    setCountryImg(country_img)

                } else {
                    console.log(error)
                    setError("No data found for this slug.");
                }
            })
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/countries?populate[centers][populate]=*&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {

                    const centersData = data.data[0].attributes.centers.data || '';
                    setCenters(centersData);

                    const center1 = centersData[0].attributes || ''
                    setCenters1(center1)

                    const imageUrls = centersData.map(center => {
                        if (centersData) {
                            return center.attributes.Image1.data.attributes.url;
                        } else {
                            return null; // or handle missing data appropriately
                        }
                    });
                    setCenterImgs(imageUrls);


                } else {
                    console.log(error)
                    setError("No data found for this slug.");
                }
            })
            .catch((error) => setError(error));

        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('search');
        setSearchQuery(query || '');

    }, [slug, location.search, server, error, i18n.language]);


    const handleFilterToggle = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const filteredCenters = centers.filter(center =>
        center.attributes.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <Navbar />
            <header>
                <div className="header-content">
                    <div className="country">
                        <Link to='/all-country'>
                            <img src={server + countryImg} alt="country" />
                        </Link>
                    </div>
                    <div className="header-top">

                        <div className="header-title">
                            <span>{centers1.Name}</span>
                            <p>{t('centers.item_1')}</p>
                        </div>
                        <div className="header-info">
                            <p>
                                {centers1.Text}
                            </p>
                            <div className="header-btn">
                                <button><Link to={'/center/' + centers1.Slug}>{t('centers.item_2')}</Link></button>
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="62" viewBox="0 0 69 62"
                                        fill="none">
                                        <ellipse cx="34.6623" cy="31" rx="33.9842" ry="31" fill="#232323" />
                                        <path
                                            d="M51.035 29.7304C52.4591 30.4804 52.4591 32.5196 51.035 33.2696L26.9057 45.9773C25.5738 46.6788 23.9737 45.713 23.9737 44.2078V18.7922C23.9737 17.287 25.5738 16.3212 26.9057 17.0227L51.035 29.7304Z"
                                            fill="url(#paint0_linear_1_14)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1_14" x1="54.3951" y1="31.5" x2="13.8333"
                                                y2="31.5" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#FCD0A8" />
                                                <stop offset="0.441565" stopColor="#D1E5D8" />
                                                <stop offset="1" stopColor="#5C9DAC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="header-filter">
                            <button id="filterHeader" onClick={handleFilterToggle}>
                                <FontAwesomeIcon icon={faFilter} />{t('centers.item_3')}
                            </button>
                            <div className={`header-filter-content ${isFilterOpen ? 'open' : ''}`}>
                                <div className="header-filter-content-top">
                                    <button id="filterHeaderClose" onClick={handleFilterToggle}>
                                        <FontAwesomeIcon icon={faSquareXmark} />
                                    </button>
                                </div>
                                <ul>
                                    {cities.map(({ id, attributes }) => (
                                        <li key={id}><Link to={`/city/${attributes.Slug}`}>{attributes.Name}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="branch-box-group">

                            {filteredCenters.length > 0 ? (
                                filteredCenters.map(({ id, attributes }, index) => (
                                    <div className="branch-box" key={index}>
                                        <a href={`/center/${attributes.Slug}`}>
                                            <div className="img">
                                                <img src={`${server}${centerImgs[index]}`} alt="center" />
                                            </div>

                                            <p>
                                                <FontAwesomeIcon
                                                    style={{ marginRight: 10 }}
                                                    icon={faArrowUpRightFromSquare}
                                                />
                                                {attributes.Name} {t('centers.item_1')}
                                            </p>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <div className='notFoundCenter'>
                                    <p>{t('centers.item_4')}</p>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </header>
            <Footer />
        </div>
    );
};

export default Center;
