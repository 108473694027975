import React, { useState } from 'react';
import Navbar from '../include/Navbar';
import Footer from '../include/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import ServicesData from './ServicesData';

const Services = () => {
  const [services, setServices] = useState(ServicesData);
  const [activeServices, setActiveServices] = useState([]); 
  const [selectedPrices, setSelectedPrices] = useState({});

  const toggleServiceDetails = (serviceId) => {
    if (activeServices.includes(serviceId)) {
      setActiveServices(activeServices.filter(id => id !== serviceId)); 
    } else {
      setActiveServices([...activeServices, serviceId]); 
    }
  };

  const handlePriceSelection = (serviceId, price, event) => {
    event.stopPropagation(); 
    setSelectedPrices({
      ...selectedPrices,
      [serviceId]: price,
    });
  };

  const calculateTotalPrice = () => {
    return Object.values(selectedPrices).reduce((total, price) => total + price, 0).toFixed(2);
  };

  return (
    <div>
      <Navbar />
      <div className='boulevard'>
          <div className="boulevard-top">
            <div className="boulevard-title">Our Services</div>
          </div>
        </div>

      <div className="services-container">
        {services.map((service) => (
          <div
            key={service.id}
            className={`service-card ${activeServices.includes(service.id) ? 'active' : ''} ${service.status === 'coming_soon' ? 'disabled' : ''}`}
          >
            <div className="service-header" onClick={service.status === 'available' ? () => toggleServiceDetails(service.id) : null}>
              <h2>{service.name}</h2>
              {service.status === 'available' ? (
                <span className="service-status available">Available</span>
              ) : (
                <span className="service-status coming-soon">Soon...</span>
              )}
              <span className="dropdown-arrow"><FontAwesomeIcon icon={faCaretDown} /></span>
            </div>
            {activeServices.includes(service.id) && service.status === 'available' && (
              <div className="service-details">
                <p>{service.description}</p>
               <div className="pricing">
  <label>
    Monthly: ${service.pricePerMonth.toFixed(2)}
  </label>
  <label>
    Yearly: ${service.pricePerYear.toFixed(2)}
  </label>
</div>

           
              </div>
            )}
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Services;
