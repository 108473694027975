import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import axios from "axios";
import { Link } from "react-router-dom"

import Navbar from './include/Navbar';
import Footer from './include/Footer';

import lebanon from '../assets/images/lebanon.png'

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { API } from '../constant';

const AllCountries = () => {
    const { id } = useParams();

    const { i18n } = useTranslation();

    const [error, setError] = useState(null);
    const [flags, setFlags] = useState([]);
    const [flagImg, setFlagImage] = useState([]);

    const server = API

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };


    useEffect(() => {
        const locale = getSelectedLanguage();

        axios
            .get(`${server}/api/countries/?populate=*&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {
                    setFlags(data.data);
                    // setFlagImage(flaflags[0].attributes.Flag.data.attributes.url);
                    const images = {};
                    data.data.forEach(({ id, attributes }) => {
                        if (attributes.Flag && attributes.Flag.data && attributes.Flag.data.attributes.url) {
                            images[id] = attributes.Flag.data.attributes.url;
                        }
                    });
                    setFlagImage(images);
                } else {
                    console.log(error)
                    setError("No data found for this slug.");
                }
            })
            .catch((error) => setError(error));
    }, [id, server, error, i18n.language]);




    return (
        <div>
            <Navbar />
            <section>
                <div className="all-counties">
                    <div className="all-counties-group">
                        {flags.map(({ id, attributes }) => (
                            <div className="all-counties-box" key={id}>
                                <Link to={`/centers/${attributes.Slug}`}>
                                    <div className="all-counties-box-info">
                                        <img src={server + flagImg[id]} alt="flag" />
                                        <h2>{attributes.Name}</h2>
                                    </div>
                                    <div className="all-counties-box-icon"><FontAwesomeIcon icon={faArrowRight} /></div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default AllCountries;
