import React, { useEffect, useState } from 'react';
import axios from "axios";

import Navbar from './include/Navbar';
import Footer from './include/Footer';
import Contact from './include/Contact'
import { useTranslation } from 'react-i18next';
import { API } from '../constant';

const Category = () => {

    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);

    const { t, i18n } = useTranslation();

    const server = API

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios
            .get(`${server}/api/categories?populate=*&locale=${locale}`)
            .then(({ data }) => setCategories(data.data))
            .catch((error) => setError(error));
    }, [server, i18n.language]);

    if (error) {
        return <div>An error occured: {error.message}</div>;
    }

    return (
        <div>
            <Navbar />

            <section>
                <div className="boulevard">
                    <div className="boulevard-content">
                        <div className="boulevard-top">
                            <div className="boulevard-title">{t('category.item_1')}</div>
                        </div>
                        <div className="boulevard-bottom">
                            <div className="boulevard-category-group">
                                {categories.map(({ id, attributes }) => (
                                    <div className="boulevard-category-box" key={id}>
                                        <a href={`/all/${attributes.Slug}`}>
                                            <img src={server + attributes.Icon.data.attributes.url} alt="category-icon" />
                                            <p>{attributes.Name}</p>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />
            <Footer />
        </div>
    );
};

export default Category;
