import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import axios from "axios";
import { Link } from "react-router-dom"

import Navbar from './include/Navbar';
import Footer from './include/Footer';

import { useTranslation } from 'react-i18next';

// Images
import lebanon from '../assets/images/lebanon.png'
import stairs from '../assets/images/stairs.png'
import calendar from '../assets/images/calendar.png'
import phone from '../assets/images/phone-call.png'
import location from '../assets/images/location.png'
import support from '../assets/images/support.png'

import { API } from '../constant';

const StartCenter = () => {

    const { slug } = useParams();

    const [error, setError] = useState(null);
    const [centers, setCenters] = useState([]);
    const [centerCategory, setCategory] = useState([]);
    const [centerImg1, setCenterImg1] = useState([]);
    const [centerImg2, setCenterImg2] = useState([]);
    const [centerImg3, setCenterImg3] = useState([]);
    const [centerImg4, setCenterImg4] = useState([]);

    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [centerImg1, centerImg2, centerImg3, centerImg4];
    const totalSlides = images.length;

    const [featuredStores, setfeaturedStores] = useState([]);

    const { t, i18n } = useTranslation();

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    const server = API

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios
            .get(`${server}/api/centers?populate=*&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {

                    const centersData = data.data[0].attributes;
                    setCenters(centersData);

                    setCategory(centersData.categories.data)

                    const image1 = centersData.Image1.data.attributes.url;
                    setCenterImg1(image1);

                    const image2 = centersData.Image2.data.attributes.url;
                    setCenterImg2(image2);

                    const image3 = centersData.Image3.data.attributes.url;
                    setCenterImg3(image3);

                    const image4 = centersData.Image4.data.attributes.url;
                    setCenterImg4(image4);

                } else {
                    setError("No data found for this slug.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/centers?populate[categories][populate]=Icon&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {

                    const centersData = data.data[0].attributes;
                    setCategory(centersData.categories.data)

                } else {
                    setError("No data found for this slug.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/centers?populate[store_offices][populate]=Image1&populate[store_offices][populate]=subcaregory&populate[store_offices][populate]=center&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {

                    const featuredStores = data.data[0].attributes;
                    setfeaturedStores(featuredStores.store_offices.data)

                } else {
                    setError("No data found for this slug.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));
    }, [slug, error, server, i18n.language]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentSlide]);

    const showSlide = (index) => {
        setCurrentSlide(index);
    };

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % totalSlides);
    };


    const dots = [];
    for (let i = 0; i < totalSlides; i++) {
        dots.push(
            <div
                key={i}
                className={`dot ${currentSlide === i ? 'active' : ''}`}
                onClick={() => showSlide(i)}
            ></div>
        );
    }


    return (
        <div>
            <Navbar />

                <div className="header-content">
                  	<div className="country">
                        <Link to='/all-country'>
                            <img src={lebanon} alt="country" />
                        </Link>
                    </div>
             </div>

            <section>
                <div className="start">
                    <div className="start-content">
                        <div className="start-l">
                            <div className="start-title">{centers.Name} {t('centerStart.item_1')}</div>
                            <div className="start-text">{centers.Text}</div>
                            <a href="#about">{t('centerStart.item_2')}</a>
                        </div>
                        <div className="start-r">
                            <div
                                className="header-slider"
                                id="slider"
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${server + images[currentSlide]}')`,
                                }}
                            ></div>
                            <div className="dots" id="dots-container">
                                {dots}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="boulevard">
                    <div className="boulevard-content">
                        <div className="boulevard-top">
                            <div className="boulevard-title">{t('centerStart.item_3')}</div>
                            <Link to={'/' + centers.Slug + '/categories'}>{t('centerStart.item_10')}</Link>
                        </div>
                        <div className="boulevard-bottom">
                            <div className="boulevard-category-group">
                                {centerCategory && centerCategory.length > 0 ? (
                                    centerCategory.slice(-4).map((center, index) => {
                                        const imageCategory = center.attributes.Icon && center.attributes.Icon.data ? center.attributes.Icon.data.attributes.url : '';

                                        return (
                                            <Link className="boulevard-category-box" to={'/category/' + centers.Slug + '/' + center.attributes.Slug} key={index}>
                                                <div>
                                                    <img src={server + imageCategory} alt="category-icon" />
                                                    <p>{center.attributes.Name}</p>
                                                </div>
                                            </Link>
                                        )
                                    })
                                ) : (
                                    <p>{t('centerStart.item_9')}</p>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="featuredStores">
                    <div className="featuredStores-content">
                        <div className="featuredStores-title">{t('centerStart.item_8')}</div>
                        <div className="featuredStores-shops">
                            {featuredStores && featuredStores.length > 0 ? (
                                featuredStores
                                    .filter(({ attributes }) => attributes.Featured)
                                    .map(({ id, attributes }, index) => {
                                        const imageFirst = attributes.Image1 && attributes.Image1.data ? attributes.Image1.data.attributes.url : '';
                                        const companyName = attributes.center && attributes.center.data ? attributes.center.data.attributes.Slug : '';
                                        const subcaregory = attributes.subcaregory && attributes.subcaregory.data ? attributes.subcaregory.data.attributes.Name : '';

                                        return (
                                            <div className="advertisingClothes-box" key={id}>
                                                <Link to={`/${companyName}/${attributes.Slug}`}>
                                                    <div className="advertisingClothes-box-img"><img src={server + imageFirst} alt='' /></div>
                                                    <div className='inline-sb'>
                                                        <h2>{attributes.Name}</h2>
                                                        <p>{subcaregory}</p>
                                                    </div>
                                                    <div className="advertisingClothes-inline-data"><img src={calendar} alt="calendar" /><p>{attributes.MondayFriday}</p></div>
                                                    <div className="advertisingClothes-inline-data"><img src={phone} alt="phone" /><p>{attributes.WhatsAppNumber}</p></div>
                                                    <div className="advertisingClothes-inline-data"><img src={stairs} alt="floor" /><p>{attributes.Floor}</p></div>
                                                </Link>
                                            </div>
                                        )
                                    })
                            ) : (
                                <p>{t('centerStart.item_9')}</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section id='about'>
                <div className="aboutUs">
                    <div className="aboutUs-content">
                        <div className="aboutUs-l">
                            <div className="aboutUs-section">About Us</div>
                            <div className="aboutUs-title">Our Story</div>
                            <div className="aboutUs-text">{centers.Description}</div>
                        </div>
                        <div className="aboutUs-r">
                            <div className="aboutUs-img-group-1">
                                <div className="aboutUs-img"><img src={server + centerImg1} alt="center" /></div>
                                <div className="aboutUs-img"><img src={server + centerImg2} alt="center" /></div>
                            </div>
                            <div className="aboutUs-img-group-2">
                                <div className="aboutUs-img"><img src={server + centerImg3} alt="center" /></div>
                                <div className="aboutUs-img"><img src={server + centerImg4} alt="center" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="contactUs">
                    <div className="contactUs-content">
                        <div className="contactUs-title">{t('contact.item_1')}</div>
                        <div className="contactUs-info">
                            <div className="contactUs-info-items">
                                <div className="contactUs-info-item">
                                    <div className="contactUs-info-item-icon"><img src={location} alt="location" /></div>
                                    <div className="contactUs-info-item-r">
                                        <div className="contactUs-info-item-title">{t('contact.item_2')}</div>
                                        <div className="contactUs-info-item-value">{centers.location}</div>
                                    </div>
                                </div>
                                <div className="contactUs-info-item">
                                    <div className="contactUs-info-item-icon"><img src={support} alt="support" /></div>
                                    <div className="contactUs-info-item-r">
                                        <div className="contactUs-info-item-title">{t('contact.item_4')}</div>
                                        <div className="contactUs-info-item-value"><a href="tel:900000007700">{centers.phone}</a></div>
                                        <div className="contactUs-info-item-value"><a href="mailto: info@example.com">{centers.email}</a></div>
                                    </div>
                                </div>
                                <div className="contactUs-info-btn">
                                    <a href="/contact-us">Contact CentersNet</a>
                                </div>
                            </div>
                            <div className="contactUs-map">
                                <iframe
                                    src={centers.map_iframe}
                                    width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default StartCenter;
