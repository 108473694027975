import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Navbar from './include/Navbar';

// Images
import stairs from '../assets/images/stairs.png'
import calendar from '../assets/images/calendar.png'
import phone from '../assets/images/phone-call.png'

import { API } from '../constant';
import { useTranslation } from 'react-i18next';

const FilterClothes = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchValue = queryParams.get('search');

    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [storeOffices, setStoreOffices] = useState([]);
    const server = API;

    const { t, i18n } = useTranslation();

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios.get(`${server}/api/categories?populate[store_offices][populate]=ImageBG&populate[Image][populate]=url&filters[Slug]=${searchValue}&locale=${locale}`)
            .then((response) => {
                if (response.data && response.data.data) {

                    const categoriesData = response.data.data[0].attributes.store_offices.data;

                    // Sort categories by Name field in ascending order
                    const sortedCategories = categoriesData.sort((a, b) => a.attributes.Name.localeCompare(b.attributes.Name));

                    setCategories(sortedCategories);


                    const imageUrls = categoriesData.map(city => {
                        if (city.attributes.ImageBG && city.attributes.ImageBG.data) {
                            return city.attributes.ImageBG.data.attributes.url;
                        } else {
                            return null; // or handle missing data appropriately
                        }
                    });
                    setStoreOffices(imageUrls);
                } else {
                    setError("Data structure from API is not as expected.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));

    }, [searchValue, error, server, i18n.language]);

    return (
        <div>
            <Navbar />
            <section>
                <div className="filterClothes">
                    <div className="advertisingClothes-box-group">
                        {categories && categories.length > 0 ? (
                            categories.map(({ id, attributes }, index) => (
                                <div className="advertisingClothes-box" key={id}>
                                    <a href={`/store/${attributes.Slug}`}>
                                        <div className="advertisingClothes-box-img"><img src={server + storeOffices[index]} alt='img' /></div>
                                        <div>
                                            <h2>{attributes.Name}</h2>
                                            <p>{t('filterStores.item_1')}</p>
                                        </div>
                                        <div className="advertisingClothes-inline-data"><img src={calendar} alt="calendar" /><p>{attributes.MondayFriday}</p></div>
                                        <div className="advertisingClothes-inline-data"><img src={phone} alt="phone" /><p>{attributes.WhatsAppNumber}</p></div>
                                        <div className="advertisingClothes-inline-data"><img src={stairs} alt="floor" /><p>{attributes.Floor}</p></div>
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p>{t('filterStores.item_2')}</p>
                        )}
                    </div>

                </div>
            </section>

        </div>
    );
};

export default FilterClothes;
