import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelope, faPhoneFlip } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Navbar from './Navbar';
import Footer from './Footer';

const ContactEmail = () => {
    // const { t } = useTranslation();

    const [focused, setFocused] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    });

    const handleFocus = (field) => {
        setFocused((prev) => ({ ...prev, [field]: true }));
    };

    const handleBlur = (field, value) => {
        setFocused((prev) => ({ ...prev, [field]: !!value }));
    };

    return (
        <section>
            <Navbar />
            <div className="contact-email">
                <div className="container">
                    <span className="big-circle"></span>
                    <img src="img/shape.png" className="square" alt="img" />
                    <div className="form">
                        <div className="contact-info">
                            <h3 className="title">Let's get in touch</h3>
                            <p className="text">
                               Fill out the form to contact us to join centersnet or any other service we offer.
                            </p>

                            <div className="info">
                                <div className="information">
                                    <FontAwesomeIcon icon={faLocationDot} />

                                    <p>Beirut, Lebanon</p>
                                </div>
                                <div className="information">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <p>contact@centersnet.com</p>
                                </div>
                                <div className="information">
                                    <FontAwesomeIcon icon={faPhoneFlip} />
                                    <p>+961 3 96 00 95</p>
                                </div>
                            </div>

                            <div className="social-media">
                                <p>Follow us and connect :</p>
                                <div className="social-icons">
                                    <a href="/">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                    <a href="/">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                    <a href="/">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </a>
                                    <a href="/">
                                        <FontAwesomeIcon icon={faWhatsapp} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="contact-form">
                            <span className="circle one"></span>
                            <span className="circle two"></span>

                            <form action="index.html" autoComplete="off">
                                <h3 className="title">Contact us</h3>
                                <div className={`input-container ${focused.name ? 'focus' : ''}`}>
                                    <input
                                        type="text"
                                        name="name"
                                        className="input"
                                        onFocus={() => handleFocus('name')}
                                        onBlur={(e) => handleBlur('name', e.target.value)}
                                    />
                                    <label htmlFor=""> Full Name</label>
                                    <span>Full name</span>
                                </div>
                                <div className={`input-container ${focused.email ? 'focus' : ''}`}>
                                    <input
                                        type="email"
                                        name="email"
                                        className="input"
                                        onFocus={() => handleFocus('email')}
                                        onBlur={(e) => handleBlur('email', e.target.value)}
                                    />
                                    <label>Email</label>
                                    <span>Email</span>
                                </div>
                                <div className={`input-container ${focused.phone ? 'focus' : ''}`}>
                                    <input
                                        type="tel"
                                        name="phone"
                                        className="input"
                                        onFocus={() => handleFocus('phone')}
                                        onBlur={(e) => handleBlur('phone', e.target.value)}
                                    />
                                    <label>Phone</label>
                                    <span>Phone</span>
                                </div>
                                <div className={`input-container textarea ${focused.message ? 'focus' : ''}`}>
                                    <textarea
                                        name="message"
                                        className="input"
                                        onFocus={() => handleFocus('message')}
                                        onBlur={(e) => handleBlur('message', e.target.value)}
                                    ></textarea>
                                    <label>Message</label>
                                    <span>Message</span>
                                </div>
                                <input type="submit" value="Send" className="btn" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
                           <Footer />
        </section>
    );
};

export default ContactEmail;
