import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/CentersNet-Logo.svg'
const Footer = () => {

    const location = useLocation();
    const [isHomePage, setIsHomePage] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const pagesToCheck = ['/', /^\/centers\/.*/]; // Use regex pattern for dynamic URLs
        const isOnHomePage = pagesToCheck.some(page => {
            if (typeof page === 'string') {
                return page === location.pathname;
            } else if (page instanceof RegExp) {
                return page.test(location.pathname);
            }
            return false;
        });
        setIsHomePage(isOnHomePage);
    }, [location.pathname]);


    return (
        <footer className={isHomePage ? 'black-background' : 'white-background'}>
            <div className="footer">
                <div className="footer-content">
                    <div className="footer-top">
                                           <div><Link className={isHomePage ? 'white-color nav-logo' : 'black-color nav-logo'} to="/">   
                     <img width="80px" height="80px" src={logo} alt="centersnet-logo" /></Link></div>
                        <div className="footer-items">
                            <ul>
                                <li><Link to="/" className={isHomePage ? 'white-color' : 'black-color'}>{t('navBar.item_5')}</Link></li>
                                <li><Link to="/spectrum#about" className={isHomePage ? 'white-color' : 'black-color '}>{t('footer.item_1')}</Link></li>
                                <li><Link to="/categories" className={isHomePage ? 'white-color' : 'black-color '}>{t('footer.item_3')}</Link></li>
                                <li><Link to="/contact-us" className={isHomePage ? 'white-color' : 'black-color '}>{t('footer.item_2')}</Link></li>
                              
                            </ul>
                        </div>
                    </div>
                    <div className={isHomePage ? 'white-background footer-hr' : 'black-background footer-hr'}></div>
                    <div className={isHomePage ? 'white-color footer-bottom' : 'black-color footer-bottom'}>
                        <p>© 2024 Spectrum Co. Ltd</p>
                        <div className="footer-social-group">
                            <div className={isHomePage ? 'white-background footer-social' : 'black-background footer-social'}></div>
                            <div className={isHomePage ? 'white-background footer-social' : 'black-background footer-social'}></div>
                            <div className={isHomePage ? 'white-background footer-social' : 'black-background footer-social'}></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
