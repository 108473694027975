import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from './include/Navbar';
import Footer from './include/Footer';
import Contact from './include/Contact';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"

// Images
import stairs from '../assets/images/stairs.png'
import calendar from '../assets/images/calendar.png'
import phone from '../assets/images/phone-call.png'

import { API } from '../constant';


const CenterStores = () => {
    const { slug, center } = useParams();
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoriesİmg, setCategoriesİmg] = useState([]);
    const [storeOffices, setStoreOffices] = useState([]);

    const [categoryName, setcategoryName] = useState([]);

    const [subcaregories, setSubcaregories] = useState([]);

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const server = API;

    const { t, i18n } = useTranslation();

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();

        axios.get(`${server}/api/store-offices?populate=*&locale=${locale}`)
            .then((response) => {
                if (response.data && response.data.data) {

                    const categoriesData = response.data.data;

                    setCategories(categoriesData);


                    const imageUrls = categoriesData.map(city => {
                        if (city.attributes.ImageBG && city.attributes.ImageBG.data) {
                            return city.attributes.ImageBG.data.attributes.url;
                        } else {
                            return null;
                        }
                    });
                    setStoreOffices(imageUrls);
                } else {
                    setError("Data structure from API is not as expected.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));

        axios.get(`${server}/api/categories?populate=*&filters[Slug]=${slug}&locale=${locale}`)
            .then((response) => {
                if (response.data && response.data.data) {

                    const categoriesImg = response.data.data[0].attributes.Image.data.attributes.url;
                    const subcategoriesData = response.data.data[0].attributes.subcaregories.data;
                    const categoryNameData = response.data.data[0].attributes.Name;
                    setcategoryName(categoryNameData)
                    setSubcaregories(subcategoriesData)
                    setCategoriesİmg(categoriesImg)

                } else {
                    setError("Data structure from API is not as expected.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));



    }, [slug, error, server, i18n.language, center]);


    const handleFilterToggle = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    return (
        <div>
            <Navbar />
            <section>
                <div className="advertisingClothes">
                    <div className="advertisingClothes-content">
                        <div className="advertisingClothes-ads"><img src={server + categoriesİmg} alt="ads" /></div>
                        <div className="advertisingClothes-title">{categoryName} {t('clothes.item_1')}</div>
                        <div className="inline">
                            <div className="subcategories">
                                <div className="title">{t('clothes.item_2')}</div>
                                <div className="hr"></div>
                                {subcaregories.map(({ id, attributes }) => (
                                    <div className="subcategory-item" key={id}>
                                        <Link to={`/subcategory/${attributes.Slug}`}>
                                            {attributes.Name}
                                        </Link>
                                    </div>
                                )
                                )}
                            </div>
                            <div className="subcategories-mobile">
                                <button onClick={handleFilterToggle}>{t('clothes.item_3')}</button>

                                <div className={`header-filter-content ${isFilterOpen ? 'open' : ''}`}>
                                    <div className="header-filter-content-top">
                                        <button id="filterHeaderClose" onClick={handleFilterToggle}>
                                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clipRule="evenodd" />
                                            </svg>

                                        </button>
                                    </div>
                                    <ul>
                                        {subcaregories.map(({ id, attributes }) => (
                                            <div className="subcategory-item" key={id}>
                                                <Link to={`/subcategory/${attributes.Slug}`}>
                                                    {attributes.Name}
                                                </Link>
                                            </div>
                                        )
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="advertisingClothes-box-group">
                                {categories && categories.length > 0 ? (
                                    categories.map(({ id, attributes }, index) => {
                                        // Filter by center (assuming center data is stored in the attributes)
                                        if (attributes.center && attributes.center.data.attributes.Slug === center && attributes.category && attributes.category.data.attributes.Slug === slug) {
                                            const subcaregory = attributes.subcaregory && attributes.subcaregory.data ? attributes.subcaregory.data.attributes.Name : '';
                                            const companyName = attributes.center && attributes.center.data ? attributes.center.data.attributes.Slug : '';

                                            return (
                                                <div className="advertisingClothes-box" key={id}>
                                                    <Link to={`/${companyName}/${attributes.Slug}`}>
                                                        <div className="advertisingClothes-box-img" style={{ backgroundImage: `url(${server + storeOffices[index]})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%' }}></div>
                                                        <div>
                                                            <h2>{attributes.Name}</h2>
                                                            <p>{subcaregory}</p>
                                                        </div>
                                                        <div className="advertisingClothes-inline-data"><img src={calendar} alt="calendar" /><p>{attributes.MondayFriday}</p></div>
                                                        <div className="advertisingClothes-inline-data"><img src={phone} alt="phone" /><p>{attributes.WhatsAppNumber}</p></div>
                                                        <div className="advertisingClothes-inline-data"><img src={stairs} alt="floor" /><p>{attributes.Floor}</p></div>
                                                    </Link>
                                                </div>
                                            );
                                        } else {
                                            return null; // Don't render if center doesn't match
                                        }
                                    })
                                ) : (
                                    <p>No data available.</p>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Contact />
            <Footer />
        </div>
    );
};

export default CenterStores;
