import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from './include/Navbar';
import Footer from './include/Footer';
import Contact from './include/Contact';

import { API } from '../constant';

import { useTranslation } from 'react-i18next';

const Cities = () => {
    const { slug } = useParams();
    const [error, setError] = useState(null);
    const [cities, setCities] = useState([]);
    const [storeOffices, setStoreOffices] = useState([]);

    const server = API;

    const { t, i18n } = useTranslation();

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios.get(`${server}/api/cities?populate[store_offices][populate]=ImageBG&filters[Slug]=${slug}&locale=${locale}`)
            .then((response) => {
                if (response.data && response.data.data) {
                    const citiesData = response.data.data[0].attributes.store_offices.data;
                    setCities(citiesData);

                    // Access ImageBG data using indexes
                    const imageUrls = citiesData.map(city => {
                        if (city.attributes.ImageBG && city.attributes.ImageBG.data) {
                            return city.attributes.ImageBG.data.attributes.url;
                        } else {
                            return null;
                        }
                    });
                    setStoreOffices(imageUrls);
                } else {
                    console.log(error)
                    setError("Data structure from API is not as expected.");
                }
            })
            .catch((error) => setError(error));

    }, [slug, server, error, i18n.language]);


    return (
        <div>
            <Navbar />
            <section>
                <div className="advertisingClothes">
                    <div className="advertisingClothes-content">
                        <div className="advertisingClothes-box-group">
                            {cities && cities.length > 0 ? (
                                cities.map(({ id, attributes }, index) => (
                                    <div className="advertisingClothes-box" key={id}>
                                        <a href={`/store/${attributes.Slug}`}>
                                            <div className="advertisingClothes-box-img"><img src={server + storeOffices[index]} alt='img' /></div>
                                            <p>{t('cities.item_1')}</p>
                                            <h2>{attributes.Name}</h2>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <p>No data available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Contact />
            <Footer />
        </div>
    );
};

export default Cities;
