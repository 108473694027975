import React from 'react';
import { useTranslation } from 'react-i18next';

// Images
import location from '../../assets/images/location.png'
import support from '../../assets/images/support.png'

const Contact = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className="contactUs">
                <div className="contactUs-content">
                    <div className="contactUs-title">{t('contact.item_1')}</div>
                    <div className="contactUs-info">
                        <div className="contactUs-info-items">
                            <div className="contactUs-info-item">
                                <div className="contactUs-info-item-icon"><img src={location} alt="location" /></div>
                                <div className="contactUs-info-item-r">
                                    <div className="contactUs-info-item-title">{t('contact.item_2')}</div>
                                    <div className="contactUs-info-item-value">{t('contact.item_3')}</div>
                                </div>
                            </div>
                            <div className="contactUs-info-item">
                                <div className="contactUs-info-item-icon"><img src={support} alt="support" /></div>
                                <div className="contactUs-info-item-r">
                                    <div className="contactUs-info-item-title">{t('contact.item_4')}</div>
                                    <div className="contactUs-info-item-value"><a href="tel:900000007700">{t('contact.item_5')}</a></div>
                                    <div className="contactUs-info-item-value"><a href="mailto: info@example.com">{t('contact.item_6')}</a></div>
                                </div>
                            </div>
                            <div className="contactUs-info-btn">
                                <a href="/contact-us">Contact CentersNet</a>
                            </div>
                        </div>
                        <div className="contactUs-map"><iframe
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3313.3649647331904!2d35.530255000000004!3d33.854484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDUxJzE2LjEiTiAzNcKwMzEnNDguOSJF!5e0!3m2!1sen!2sfr!4v1718114915196!5m2!1sen!2sfr"
                            width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade" title="map"></iframe></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
