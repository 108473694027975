import React, { useState, useEffect } from 'react';

import Navbar from './include/Navbar';
import Footer from './include/Footer';

// Images
import branch1 from '../assets/images/branch1.png'
import branch2 from '../assets/images/branch2.png'
import branch3 from '../assets/images/branch3.png'
import branch4 from '../assets/images/branch4.png'
import Contact from './include/Contact';

import { useTranslation } from 'react-i18next';

const StartNow = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [branch1, branch2, branch3, branch4];
    const totalSlides = images.length;
    const { t } = useTranslation();

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentSlide]);

    const showSlide = (index) => {
        setCurrentSlide(index);
    };

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % totalSlides);
    };


    const dots = [];
    for (let i = 0; i < totalSlides; i++) {
        dots.push(
            <div
                key={i}
                className={`dot ${currentSlide === i ? 'active' : ''}`}
                onClick={() => showSlide(i)}
            ></div>
        );
    }

    return (
        <div>
            <Navbar />
            <section>
                <div className="start">
                    <div className="start-content">
                        <div className="start-l">
                            <div className="start-title">{t('startNow.item_1')}</div>
                            <div className="start-text">{t('startNow.item_2')}</div>
                            <a href="#about">{t('startNow.item_3')}</a>
                        </div>
                        <div className="start-r">
                            <div
                                className="header-slider"
                                id="slider"
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${images[currentSlide]}')`,
                                }}
                            ></div>
                            <div className="dots" id="dots-container">
                                {dots}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id='about'>
                <div className="aboutUs">
                    <div className="aboutUs-content">
                        <div className="aboutUs-l">
                            <div className="aboutUs-section">{t('startNow.item_23')}</div>
                            <div className="aboutUs-title">{t('startNow.item_24')}</div>
                            <div className="aboutUs-text">{t('startNow.item_25')}</div>
                        </div>
                        <div className="aboutUs-r">
                            <div className="aboutUs-img-group-1">
                                <div className="aboutUs-img"><img src={branch1} alt="img" /></div>
                                <div className="aboutUs-img"><img src={branch2} alt="img" /></div>
                            </div>
                            <div className="aboutUs-img-group-2">
                                <div className="aboutUs-img"><img src={branch3} alt="img" /></div>
                                <div className="aboutUs-img"><img src={branch4} alt="img" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />

            <Footer />
        </div>
    );
};

export default StartNow;
