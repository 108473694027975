import React, { useEffect, useState } from 'react';
import axios from "axios";

import Navbar from './include/Navbar';
import Footer from './include/Footer';
import Contact from './include/Contact'
import { useTranslation } from 'react-i18next';
import { API } from '../constant';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom"

const CenterAllCategory = () => {
    const { slug } = useParams();

    const [error, setError] = useState(null);
    const [centers, setCenters] = useState([]);
    const [centerCategory, setCategory] = useState([]);

    const { t, i18n } = useTranslation();

    const server = API

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios
            .get(`${server}/api/centers?populate[categories][populate]=Icon&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {

                    const centersData = data.data[0].attributes;
                    setCenters(centersData);
                    setCategory(centersData.categories.data)

                } else {
                    setError("No data found for this slug.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));
    }, [server, i18n.language, error, slug]);

    if (error) {
        return <div>An error occured: {error.message}</div>;
    }

    return (
        <div>
            <Navbar />

            <section>
                <div className="boulevard">
                    <div className="boulevard-content">
                        <div className="boulevard-top">
                            <div className="boulevard-title">{t('category.item_1')}</div>
                        </div>
                        <div className="boulevard-bottom">
                            <div className="boulevard-category-group">
                                {centerCategory && centerCategory.length > 0 ? (
                                    centerCategory.map((center, index) => {
                                        const imageCategory = center.attributes.Icon && center.attributes.Icon.data ? center.attributes.Icon.data.attributes.url : '';

                                        return (
                                            <div className="boulevard-category-box" key={index}>
                                                <Link to={'/' + centers.Slug + '/' + center.attributes.Slug}>
                                                    <img src={server + imageCategory} alt="category-icon" />
                                                    <p>{center.attributes.Name}</p>
                                                </Link>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <p>{t('centerStart.item_9')}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />
            <Footer />
        </div>
    );
};

export default CenterAllCategory;
