const ServicesData = [
    {
      id: 1,
      name: 'Collection of Orders',
      description: 'Manage and track orders from multiple sources in a single platform.',
      pricePerMonth: 29.99,
      pricePerYear: 299.99,
      status: 'available',
    },
    {
      id: 2,
      name: 'Appointments System',
      description: 'Streamline scheduling and manage appointments with ease.',
      pricePerMonth: 19.99,
      pricePerYear: 199.99,
      status: 'available',
    },
    {
      id: 3,
      name: 'Payments System',
      description: 'Secure and flexible payment processing for your business.',
      status: 'coming_soon',
    },
    {
      id: 4,
      name: 'Centers Management System',
      description: 'Manage and streamline center operations with ease.',
      status: 'coming_soon',
    },
    {
        id: 5,
        name: 'Building Management System',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 6,
        name: 'Accounting Cloud System',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 7,
        name: 'Pos Cloud System',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 8,
        name: 'Calender',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 9,
        name: 'Reminder System',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 10,
        name: 'Software Clouds',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 11,
        name: 'Clouds System',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 12,
        name: 'Social Media Management',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 13,
        name: 'OCR Management',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 14,
        name: 'Emails Management',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 15,
        name: 'Documents Converter System',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 16,
        name: 'online Store Systems',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 17,
        name: 'Sponsors Services',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
      {
        id: 18,
        name: 'Communications Systems',
        description: 'Manage and streamline center operations with ease.',
        status: 'coming_soon',
      },
    
  ];
  
  export default ServicesData;
  