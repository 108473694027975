import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { API } from '../../constant';

// Components
import Home from '../Home';
import StartNow from '../StartNow';
import Categories from '../Category';
import Stores from '../Clothes';
import About from '../About';
import Countries from '../Countries';
import AllCountries from '../AllCountries';
import Cities from '../Cities';
import Center from '../Centers';
import StartCenter from '../CenterStart';
import FilterClothes from '../FilterStores';
import SubCategory from '../Subcategory';
import CenterAllCategory from '../CenterAllCategory';
import CenterStores from '../CenterStores';
import ContactEmail from '../include/ContactEmail';
import Loader from '../include/Loader';
import ScrollToTop from '../include/ScrollToTop';
import Services from '../services/Services';

const Navigate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const server = API;
    const api_key = '65ce50d5425e4401bd2952e65a411af8';
    const [availableCountries, setAvailableCountries] = useState([]);

    useEffect(() => {
        const fetchAvailableCountries = async () => {
            try {
                const response = await axios.get(`${server}/api/countries/?populate=*`);
                const countriesList = response.data.data.map(country => country.attributes.Name.toLowerCase());
                setAvailableCountries(countriesList);
            } catch (error) {
                console.error("Error fetching available countries:", error);
            }
        };

        const fetchGeolocation = async () => {
            try {
                if (!localStorage.getItem('geolocationChecked')) {
                    const geoResponse = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${api_key}`);
                    const country = geoResponse.data.country_name.toLowerCase();

                    if (availableCountries.includes(country)) {
                        navigate(`/centers/${country}`);
                    } else {
                        navigate('/');
                    }

                    localStorage.setItem('geolocationChecked', 'true');
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Geolocation error:", error);
                navigate('/');
            } finally {
                setIsLoading(false);
            }
        };

        const init = async () => {
            await fetchAvailableCountries();
            fetchGeolocation();
        };

        init();
    }, [navigate, server, api_key, availableCountries]);

    useEffect(() => {
        const handleNavigation = () => {
            setIsLoading(true);
        };
        
        handleNavigation();

        return () => {
            setIsLoading(false);
        };
    }, [location.pathname]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/centers/:slug" element={<Center />} />
                <Route path="/center/:slug" element={<StartCenter />} />
                <Route path="/spectrum" element={<StartNow />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/all/:slug" element={<Stores />} />
                <Route path="/:slug/:slug" element={<About />} />
                <Route path="/all-country/" element={<AllCountries />} />
                <Route path="/country/:slug" element={<Countries />} />
                <Route path="/city/:slug" element={<Cities />} />
                <Route path="/user-filter" element={<FilterClothes />} />
                <Route path="/subcategory/:slug" element={<SubCategory />} />
                <Route path="/:slug/categories" element={<CenterAllCategory />} />
                <Route path="/category/:center/:slug" element={<CenterStores />} />
                <Route path="/contact-us" element={<ContactEmail />} />
                <Route path="/services" element={<Services />} />
            </Routes>
        </>
    );
};

export default Navigate;
