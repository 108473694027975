import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import axios from "axios";

import Navbar from './include/Navbar';
import Footer from './include/Footer';
import Contact from './include/Contact'

// Images
import stairs from '../assets/images/stairs.png'
import web from '../assets/images/world-wide-web.png'
import instagram from '../assets/images/instagram.png'
import facebook from '../assets/images/facebook.png'
import whatsapp from '../assets/images/whatsapp.png'
import gmail from '../assets/images/gmail.png'

import { API } from '../constant';

const AboutProduct = () => {

    const { slug } = useParams();

    const { t, i18n } = useTranslation();

    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [image1, setImage1] = useState([]);
    const [image2, setImage2] = useState([]);
    const [image3, setImage3] = useState([]);
    const [imageBg, setImageBg] = useState([]);
    const [logo, setLogo] = useState([]);
    const [sliderImg, setSliderImg] = useState([]);

    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    const [productImg, setProductImg] = useState([]);

    const server = API;

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };

    useEffect(() => {
        const locale = getSelectedLanguage();
        axios
            .get(`${server}/api/store-offices/?populate=*&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {
                    const attributes = data.data[0].attributes;
                    setCategories(attributes);
                    setImage1(attributes.Image1.data.attributes.url);
                    setImage2(attributes.Image2.data.attributes.url);
                    setImage3(attributes.Image3.data.attributes.url);
                    setImageBg(attributes.ImageBG.data.attributes.url);

                    const sliderImagesData = data.data[0].attributes.Slider.data;
                    // Extracting URLs from sliderImagesData
                    const extractedUrls = sliderImagesData.map(item => item.attributes.url);
                    setSliderImg(extractedUrls);

                    setServices(attributes.services.data)

                    setLogo(attributes.Logo.data[0].attributes.url)


                } else {
                    setError("No data found for this slug.");
                    console.log(error)
                }
            })
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/store-offices/?populate[products][populate]=Image&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {
                    const attributes = data.data[0].attributes;

                    const productsData = attributes.products.data

                    setProducts(productsData)
                    // Access ImageBG data using indexes
                    const imageUrls = productsData.map(products => {
                        if (products.attributes.Image && products.attributes.Image.data) {
                            return products.attributes.Image.data.attributes.url;
                        } else {
                            return null;
                        }
                    });
                    setProductImg(imageUrls);
                } else {
                    setError("No data found for this slug.");
                    console.log(error);
                }
            })
            .catch((error) => setError(error));
    }, [slug, error, server, i18n.language]);


    return (
        <div>
            <Navbar />
            <section>
                <div className="aboutProduct">
                    <div className="aboutProduct-bg"><img src={server + imageBg} alt="add-bg" /></div>
                    <div className="aboutProduct-content">
                        <div className="aboutProduct-info">
                            <div className="aboutProduct-l">
                                <div className="aboutProduct-greeting">
                                    <div className="logo">
                                        <img src={server + logo} alt="img" />
                                    </div>
                                    <span>{categories.Name}</span>
                                </div>
                                <div className="aboutProduct-text">{categories.Text1}</div>
                                <div className="aboutProduct-text">{categories.Text2}</div>
                                <div className="aboutProduct-floor">
                                    <img src={stairs} alt="stairs" />
                                    <p>{categories.Floor}</p>
                                </div>
                                <div className="aboutProduct-workday">
                                    <div className="aboutProduct-workday-title">{t('about.item_2')}</div>
                                    <div className="aboutProduct-workday-value">
                                        <p>{t('about.item_3')}: <br /><span>{categories.MondayFriday}</span> </p>
                                        <p>{t('about.item_4')}: <br /><span>{categories.SaturdaySunday}</span></p>
                                    </div>
                                </div>
                                <div className="aboutProduct-contact-info">
                                    <div className="aboutProduct-contact-title">{t('about.item_5')}</div>
                                    <div className="aboutProduct-contact">
                                        <div className="aboutProduct-contact-item">
                                            <img src={web} alt="contact" />
                                            <a href={categories.WebsiteLink}>{categories.WebsiteName}</a>
                                        </div>
                                        <div className="aboutProduct-contact-item">
                                            <img src={instagram} alt="contact" />
                                            <a href={categories.InstagramLink}>{categories.InstagramName}</a>
                                        </div>
                                        <div className="aboutProduct-contact-item">
                                            <img src={facebook} alt="contact" />
                                            <a href={categories.FacebookLink}>{categories.FacebookName}</a>
                                        </div>
                                        <div className="aboutProduct-contact-item">
                                            <img src={whatsapp} alt="contact" />
                                            <a href="/">{categories.WhatsAppNumber}</a>
                                        </div>
                                        <div className="aboutProduct-contact-item">
                                            <img src={gmail} alt="contact" />
                                            <a href={'mailto:' + categories.Email}>{categories.Email}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-r">
                                <div className="about-r-img1"><img src={server + image1} alt="about-img" /></div>
                                <div className="about-r-img2"><img src={server + image2} alt="about-img" /></div>
                                <div className="about-r-img3"><img src={server + image3} alt="about-img" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="gallery-about">
                {sliderImg.map((imageUrl, index) => (
                    <div
                        key={index}
                        className='gallery-about-item'
                    >
                        <img src={server + imageUrl} alt={`gallery ${index}`} />
                    </div>
                ))}
            </div>

            <div className="services">
                <h1 className='title'>Our Services</h1>
                <div className="services-group">
                    {services && services.length > 0 ? (
                        services.map(({ id, attributes }) => (
                            <div className="services-box" key={id}>
                                <h3>{attributes.Name}</h3>
                            </div>
                        ))
                    ) : (
                        <p>No data available.</p>
                    )}
                </div>
            </div>
            <div className="products">
                <div className="title">{t('detailPage.item_2')}</div>
                <div className="products-group">
                    {products && products.length > 0 ? (
                        products.map(({ id, attributes }, index) => (
                            <div className="product" key={id}>
                                <div className="image"><img src={server + productImg[index]} alt="product" /></div>
                                <div className="name">{attributes.Name}</div>
                                <div className="price">${attributes.Price}</div>
                            </div>
                        ))
                    ) : (
                        <p>No data available.</p>
                    )}
                </div>
            </div>

            <hr className='about-line' />

            <Contact />
            <Footer />
        </div>
    );
};

export default AboutProduct;
