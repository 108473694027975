import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import { API } from '../../constant';

import axios from "axios";

// Images
import search from '../../assets/images/search.svg'
import logo from '../../assets/images/CentersNet-Logo.svg'


import LanguageSwitcher from './LanguageSwitcher';

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";


const Navbar = () => {

    const { slug } = useParams();

    const { t, i18n } = useTranslation();

    const location = useLocation();
    const [error, setError] = useState(null);
    const [isHomePage, setIsHomePage] = useState(false);
    const [countryImg, setCountryImg] = useState([]);
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleDropdownClick = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const getSelectedLanguage = () => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        return storedLanguage ? storedLanguage : 'en';
    };


    const server = API;

    useEffect(() => {

        const locale = getSelectedLanguage();

        const pagesToCheck = ['/', /^\/centers\/.*/];
        const isOnHomePage = pagesToCheck.some(page => {
            if (typeof page === 'string') {
                return page === location.pathname;
            } else if (page instanceof RegExp) {
                return page.test(location.pathname);
            }
            return false;
        });
        setIsHomePage(isOnHomePage);

        axios
            .get(`${server}/api/countries?populate=*&filters[Slug]=${slug}&locale=${locale}`)
            .then(({ data }) => {
                if (data && data.data && data.data.length > 0) {
                    setCountryImg(data.data[0].attributes.Flag.data.attributes.url);
                } else {
                    console.log(error)
                    setError("No data found for this slug.");
                }
            })
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/categories?populate=*&locale=${locale}`)
            .then(({ data }) => setCategories(data.data))
            .catch((error) => setError(error));

        axios
            .get(`${server}/api/services?locale=${locale}`)
            .then(({ data }) => setServices(data.data))
            .catch((error) => setError(error));

    }, [location.pathname, server, error, slug, i18n.language]);

    const navigate = useNavigate();

    const handleSearchInputChange = e => {
        setSearchValue(e.target.value);
    };

    const handleSearchSubmit = e => {
        e.preventDefault();
        const searchUrl = location.pathname.includes('/centers')
            ? `/centers/${slug}?search=${searchValue}`
            : `/user-filter?search=${searchValue}`;
        navigate(searchUrl);
        window.location.reload();
    };

    return (
        <nav className={isHomePage ? 'black-background' : 'white-background'}>
            <div className="navbar">
                <div className="navbar-content">
                    <div><Link className={isHomePage ? 'white-color nav-logo' : 'black-color nav-logo'} to="/">   
                     <img width="75px" height="75px" src={logo} alt="centersnet-logo" /></Link></div>
                    <div className="nav-items">
                        <ul>
                            <li><Link to="/" className={isHomePage ? 'white-color' : 'black-color'}>{t('navBar.item_5')}</Link></li>
                            <li><Link to="/spectrum#about" className={isHomePage ? 'white-color' : 'black-color'}>{t('navBar.item_2')}</Link></li>
                            <li>
                                <div className="navDropdown" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                                    <Link to="/categories" className={isHomePage ? 'white-color' : 'black-color'}>{t('navBar.item_3')}</Link>
                                    {isOpen && (
                                        <div className="navDropdown-content">
                                            <div className="servicesList">
                                                <ul className="servicesList__ul">
                                                    {categories.map(({ id, attributes }) => (
                                                        <li key={id}>
                                                            <a href={`/all/${attributes.Slug}`}>
                                                                {attributes.Name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <div className="navDropdown" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>

                                    <a href="/services" className={isHomePage ? 'white-color' : 'black-color'}>
                                        {t('navBar.item_4')}
                                    </a>
                                    {isOpen && (
                                        <div className="navDropdown-content">
                                            <div className="servicesList">
                                                <ul className="servicesList__ul">

                                                    <li>
                                                        <Link to={`/services`}>
                                                            {t('featuresAndServices.CollectionOfStores')}
                                                            <br />
                                                            <span className='available'>Available</span>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to={`/services`}>
                                                            {t('featuresAndServices.appointmentsSystem')}
                                                            <br />
                                                            <span className='available'>Available</span>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        {t('featuresAndServices.paymentsSystem')}
                                                        <br />
                                                        <span className='comin-soon'>Soon...</span>
                                                    </li>
                                                    <li>
                                                        {t('featuresAndServices.centersManagementSystem')}
                                                        <br />
                                                        <span className='comin-soon'>Soon...</span>
                                                    </li>

                                                    <li>
                                                        {t('featuresAndServices.buildingManagementSystem')}
                                                        <br />
                                                        <span className='comin-soon'>Soon...</span>
                                                    </li>

                                                    <li>{t('featuresAndServices.accountingCloudSystems')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.posCloudSystems')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.calendar')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.remindersSystem')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    {/* <li>{t('featuresAndServices.tasksManagementSystem')} <br /><span className='comin-soon'>Soon...</span></li> */}
                                                    <li>{t('featuresAndServices.softwaresCloud')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>
                                                        {t('featuresAndServices.toolsCloud')}
                                                        <br />
                                                        <span className='comin-soon'>Soon...</span>
                                                    </li>
                                                    <li>{t('featuresAndServices.socialMediaManagement')}<br /> <span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.emailsManagement')}<br /> <span className='comin-soon'>Soon...</span></li>
                                                    <li>
                                                        {t('featuresAndServices.ocrSystems')}
                                                        <br />
                                                        <span className='comin-soon'>Soon...</span>
                                                    </li>
                                                    <li>{t('featuresAndServices.documentsConverter')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.onlineStoreSystems')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.sponsorsServices')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.communicationsSystems')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.paymentsByPhoneSystem')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    {/* <li>{t('featuresAndServices.govermentIndex')} <br /> <span className='comin-soon'>Soon...</span></li> */}
                                                    <li>{t('featuresAndServices.countriesInfo')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.phoneIndex')} <br /> <span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.virtualExpoAndExhibition')}<br /> <span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.expensesSystem')}<br /><span className='comin-soon'>Soon...</span></li>
                                                    <li>{t('featuresAndServices.personalExpensesSystem')}<br /><span className='comin-soon'>Soon...</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li><Link to="/all-country" className={isHomePage ? 'white-color' : 'black-color'}>{t('navBar.item_1')}</Link></li>
                            <li><Link to="/contact-us" className={isHomePage ? 'white-color' : 'black-color'}>{t('navBar.item_6')}</Link></li>
                        </ul>
                    </div>

                    <div className="">
                        <div className="search-flag">
                            <LanguageSwitcher />
                            <div className="nav-search">
                                <div className="nav-search-box">
                                    <form onSubmit={handleSearchSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchValue}
                                            onChange={handleSearchInputChange} />
                                        <button type="submit">
                                            <img src={search} alt="search" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="login">
                                <a href="http://admin.centersnet.com/admin">
                                    <FontAwesomeIcon icon={faRightToBracket} className={isHomePage ? 'white-color' : 'black-color'} />
                                </a>
                            </div>
                            {countryImg && countryImg.length > 0 && (
                                <div className="country-img">
                                    <Link to="/all-country/">
                                        <img src={server + countryImg} alt="country-img" />
                                    </Link>
                                </div>
                            )}
                            <div className="sidebar">
                                <input type="checkbox" id="toggleSidebar" />
                                <label className="hamburger" htmlFor="toggleSidebar">
                                    <svg viewBox="0 0 32 32">
                                        <path className={isHomePage ? 'line line-top-bottom' : 'line line-top-bottom line-dark'}
                                            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22">
                                        </path>
                                        <path className={isHomePage ? 'line' : 'line line-dark'} d="M7 16 27 16"></path>
                                    </svg>
                                </label>
                                <div className={isHomePage ? 'sidebar-content' : 'sidebar-content sidebar-content-white'}>
                                    <div className="sidebar-logo">
                                        <img src="" alt="logo" />
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                    <div className="sidebar-items">
                                        <ul >
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/all-country">Country</Link></li>
                                            <li><Link to="/spectrum#about">About us</Link></li>
                                            <li>
                                                <span
                                                    className={`drop ${openDropdown === 1 ? 'show' : ''}`}
                                                    onClick={() => handleDropdownClick(1)}
                                                >
                                                    <div className="drop-main">Categories</div>
                                                </span>
                                                <ul className="droplist" style={{ maxHeight: openDropdown === 1 ? '40vh' : '0' }}>
                                                    {categories.map(({ id, attributes }) => (
                                                        <li key={id}>
                                                            <a href={`/stores/${attributes.Slug}`}>
                                                                {attributes.Name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="list-item">
                                                <span
                                                    className={`drop ${openDropdown === 2 ? 'show' : ''}`}
                                                    onClick={() => handleDropdownClick(2)}
                                                >
                                                    <div className="drop-main">Services</div>
                                                </span>
                                                <ul className="droplist" style={{ maxHeight: openDropdown === 2 ? '40vh' : '0' }}>
                                                    <li>
                                                        {t('featuresAndServices.paymentsSystem')}
                                                        <br />
                                                        <span className='comin-soon'>Coming soon...</span>
                                                    </li>
                                                    <li>
                                                        {t('featuresAndServices.centersManagementSystem')}
                                                        <br />
                                                        <span className='available'>Available</span>
                                                    </li>
                                                    <li>
                                                        {t('featuresAndServices.buildingManagementSystem')}
                                                        <br />
                                                        <span className='available'>Available</span>
                                                    </li>
                                                    <li>
                                                        {t('featuresAndServices.appointmentsSystem')}
                                                        <br />
                                                        <span className='available'>Available</span>
                                                    </li>
                                                    <li>{t('featuresAndServices.accountingCloudSystems')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.posCloudSystems')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.calendar')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.remindersSystem')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    {/* <li>{t('featuresAndServices.tasksManagementSystem')} <br /><span className='comin-soon'>Coming soon...</span></li> */}
                                                    <li>{t('featuresAndServices.softwaresCloud')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>
                                                        {t('featuresAndServices.toolsCloud')}
                                                        <br />
                                                        <span className='available'>Available</span>
                                                    </li>
                                                    <li>{t('featuresAndServices.socialMediaManagement')}</li>
                                                    <li>{t('featuresAndServices.emailsManagement')}</li>
                                                    <li>
                                                        {t('featuresAndServices.ocrSystems')}
                                                        <br />
                                                        <span className='available'>Available</span>
                                                    </li>
                                                    <li>{t('featuresAndServices.documentsConverter')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.onlineStoreSystems')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.sponsorsServices')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.communicationsSystems')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.paymentsByPhoneSystem')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    {/* <li>{t('featuresAndServices.govermentIndex')} <br /><span className='available'>Available</span></li> */}
                                                    <li>{t('featuresAndServices.countriesInfo')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.phoneIndex')} <br /><span className='available'>Available</span></li>
                                                    <li>{t('featuresAndServices.virtualExpoAndExhibition')}</li>
                                                    <li>{t('featuresAndServices.expensesSystem')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                    <li>{t('featuresAndServices.personalExpensesSystem')}<br /><span className='comin-soon'>Coming soon...</span></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/categories">Categories</Link></li>
                                            <li><Link to="/contact-us">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sidebar-flag">

                        </div>
                    </div>

                </div>
            </div>
        </nav >
    );
};

export default Navbar;
