import React, { useState } from 'react';
import { Link } from "react-router-dom"

import Navbar from './include/Navbar';

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSquareXmark, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Footer from './include/Footer';

import { useTranslation } from 'react-i18next';

// Images
import lebanon from '../assets/images/lebanon.png'
import citycenter from '../assets/images/citycenterbeirut.jpg';
import abcverdun from '../assets/images/abcverdun.jpeg';
import citymall from '../assets/images/citymall.jpeg';
import qubicbusinesscenter from '../assets/images/qubicbusinesscenter.jpeg';
import thespot from '../assets/images/thespot.jpeg';
import centromall from '../assets/images/centromall.jpg';
import fadlallah from '../assets/images/fadlallah.jpg';
import lemall from '../assets/images/lemall.jpeg';

const Home = () => {


    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const { t } = useTranslation();

    const handleFilterToggle = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    return (
        <div>
            <Navbar />
            <header>
                <div className="header-content">
                  	<div className="country">
                        <Link to='/all-country'>
                            <img src={lebanon} alt="country" />
                        </Link>
                    </div>
                    <div className="header-top">
                        <div className="header-title">
                            <span>{t('home.item_1')}</span>
                            <p>{t('home.item_2')}</p>
                        </div>
                        <div className="header-info">
                            <p>{t('home.item_3')}</p>
                            <div className="header-btn">
                                <button><Link to="/spectrum">{t('home.item_4')}</Link></button>
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="62" viewBox="0 0 69 62"
                                        fill="none">
                                        <ellipse cx="34.6623" cy="31" rx="33.9842" ry="31" fill="#232323" />
                                        <path
                                            d="M51.035 29.7304C52.4591 30.4804 52.4591 32.5196 51.035 33.2696L26.9057 45.9773C25.5738 46.6788 23.9737 45.713 23.9737 44.2078V18.7922C23.9737 17.287 25.5738 16.3212 26.9057 17.0227L51.035 29.7304Z"
                                            fill="url(#paint0_linear_1_14)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1_14" x1="54.3951" y1="31.5" x2="13.8333"
                                                y2="31.5" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#FCD0A8" />
                                                <stop offset="0.441565" stopColor="#D1E5D8" />
                                                <stop offset="1" stopColor="#5C9DAC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="header-filter">
                            <button id="filterHeader" onClick={handleFilterToggle}>
                                <FontAwesomeIcon icon={faFilter} />{t('home.item_5')}
                            </button>
                            <div className={`header-filter-content ${isFilterOpen ? 'open' : ''}`}>
                                <div className="header-filter-content-top">
                                    <button id="filterHeaderClose" onClick={handleFilterToggle}>
                                        <FontAwesomeIcon icon={faSquareXmark} />
                                    </button>
                                </div>
                                <ul>
                                    {/* List of cities Lebanon */}
                                    {/* <li key={id}><Link to='/'></Link></li> */}
                                </ul>
                            </div>
                        </div>
                      
                            </div>
                         <div className="branch-box-group">
                            <div className="branch-box">
                                <a href="/center/CityCenterBeirut">
    <div className="img">
                                    <img src={citycenter} alt="center" />
                                               </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_6')}</p>
                                </a>
                            </div>
                            <div className="branch-box">
                                <a href="/center/AbcVerdun">    <div className="img">
      
                                    <img src={abcverdun} alt="center" />   
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_7')}</p>
                                </a>
                            </div>
                            <div className="branch-box">
                                <a href="/center/CityMall">    <div className="img">
   
                                    <img src={citymall} alt="center" />   
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_8')}</p>
                                </a>
                            </div>
                            <div className="branch-box">
                                <a href="/center/QubicBusinessCenter">    <div className="img">
     
                                    <img src={qubicbusinesscenter} alt="center" />  
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_9')}</p>
                                </a>
                            </div>
                              <div className="branch-box">
                                <a href="/center/TheSpot">    <div className="img">
  
                                    <img src={thespot} alt="center" />    
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_10')}</p>
                                </a>
                            </div>
                              <div className="branch-box">
                                <a href="/center/CentroMall">    <div className="img">
   
                                    <img src={centromall} alt="center" />
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_11')}</p>
                                </a>
                            </div>
                              <div className="branch-box">
                                <a href="/center/Fadlallah-And-Fakih-Center">    <div className="img">
                                    <img src={fadlallah} alt="center" />
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_12')}</p>
                                </a>
                            </div>
                               <div className="branch-box">
                                <a href="/center/LeMall">    <div className="img">

                                    <img src={lemall} alt="center" /> 
           </div>
                                    <p><FontAwesomeIcon style={{ marginRight: 10 }} icon={faArrowUpRightFromSquare} />{t('home.item_13')}</p>
                                </a>
                            </div>
                        </div>
                    </div>

            </header>
            <Footer />
        </div>
    );
};

export default Home;
